import { useEffect, useState } from "react";
import {
  createStyles,
  Group,
  Stack,
  Text,
  Pagination,
  ActionIcon,
  Title,
  Select,
  TextInput,
  Paper,
  Table,
  Badge,
  Avatar,
  Modal,
  Textarea,
  Button,
  Menu,
  Loader,
  Center,
  Checkbox,
  Flex,
  MultiSelect,
} from "@mantine/core";
import { IoIosSearch } from "react-icons/io";
import { BsPencil, BsThreeDots } from "react-icons/bs";
import { FiSend, FiCheck, FiX, FiUser, FiInfo } from "react-icons/fi";
import differenceInDays from "date-fns/differenceInDays";
import {
  UserRoles,
  UserStatus,
  User,
  UserRolesLabel,
  UserWarning,
  StaffRoles,
  StaffRolesLabel,
} from "../../types";
import {
  convertStringDecimalToNumber,
  formatDecimalNumber,
  getUserRolesLabel,
} from "../../utils";
import { format } from "date-fns";
import { useUserService } from "../../services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { toast } from "react-toastify";

//! Need to refactor because not structured and stable...

export default function Users() {
  const navigate = useNavigate();
  const {
    getUsersByProperty,
    updateUser,
    deleteUser,
    getUserById,
    sendWarningToUser,
    getWarningsByUser,
    exportUsersData,
  } = useUserService();
  const { classes } = useStyles();

  const [loading, setLoading] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const [users, setUsers] = useState<User[]>([]);
  const [showSelectedUser, setShowSelectedUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | any>();
  const [selectedUserParentsInfo, setSelectedUserParentsInfo] =
    useState<JSX.Element | null>(null);

  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebouncedValue(searchText, 300); // 300ms delay

  const [userRole, setUserRole] = useState<string>("");
  const [userStaffRolesFilter, setUserStaffRolesFilter] =
    useState<StaffRoles[]>();
  const [userStatus, setUserStatus] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isBirthdayWeekFilter, setIsBirthdayWeekFilter] =
    useState<boolean>(false);

  const [userWarnings, setUserWarnings] = useState<UserWarning[]>([]);
  const [exportUsersDataIsLoading, setExportUsersDataIsLoading] =
    useState(false);

  const fetchUsers = () => {
    getUsersByProperty(
      {
        error: console.error,
        loading: (value) => setLoading(value),
        success: (res) => {
          setUsers(res.users);
          setTotalPages(Math.ceil(res.count / 20));
        },
      },
      {
        text: debouncedSearchText,
        ...(userRole && { role: userRole }),
        ...(userStaffRolesFilter && {
          staffRoles: userStaffRolesFilter,
        }),
        ...(userStatus && { status: userStatus }),
        ...(isBirthdayWeekFilter && { isBirthdayWeek: isBirthdayWeekFilter }),
      },
      { page: page - 1 }
    );
  };

  useEffect(() => {
    fetchUsers();
  }, [
    page,
    debouncedSearchText,
    userRole,
    userStaffRolesFilter,
    userStatus,
    isBirthdayWeekFilter,
  ]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      getUserById(
        {
          error: (error) => {
            console.error(error);
          },
          success: (user) => {
            setSelectedUser(user);
            setShowSelectedUser(true);
          },
        },
        id
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (showSelectedUser) {
      setSelectedUserParentsInfo(null);
      computeParentsInfoJSX(selectedUser);
      getWarningsByUser(
        {
          error: console.error,
          success: (res) => {
            setUserWarnings(res);
          },
        },
        selectedUser._id
      );
    }
  }, [showSelectedUser]);

  const cleanIdQueryParams = () => {
    if (searchParams.has("id")) {
      searchParams.delete("id");
      setSearchParams(searchParams);
    }
  };

  const computeParentsInfoJSX = async (currentUser: User) => {
    if (
      !currentUser ||
      currentUser.role !== UserRoles.USER ||
      currentUser.level === 0
    )
      return;

    let currentParentId: string | undefined = currentUser.parent as string;

    if (!currentParentId) return;

    let jsx = <></>;

    while (currentParentId) {
      const currentParent = await getUserById({}, currentParentId);
      jsx = (
        <>
          {jsx}
          <p style={{ fontSize: 14 }}>
            {currentParent.firstName + " " + currentParent.lastName} - Niveau{" "}
            {currentParent.level}
          </p>
        </>
      );
      if (currentParent.level > 0 && currentParent.parent) {
        currentParentId = currentParent.parent as string;
      } else {
        currentParentId = undefined;
      }
    }
    setSelectedUserParentsInfo(jsx);
  };

  const rows = users.map((user) => (
    <tr key={user._id} style={{ position: "relative" }}>
      <td>
        <Group>
          <Avatar color="dark" radius="xl">
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
          <Stack style={{ gap: 0 }}>
            {user.firstName + " " + user.lastName}
            <Text>{getUserRolesLabel(user.role, user.staffRoles)}</Text>
          </Stack>
        </Group>
      </td>
      <td>@{user.username}</td>
      <td>
        {"Il y'a " +
          differenceInDays(new Date(), new Date(user.lastActivity)) +
          " jour(s)"}
      </td>
      <td>{formatDecimalNumber(convertStringDecimalToNumber(user._xp))}</td>
      <td>
        {formatDecimalNumber(convertStringDecimalToNumber(user._balance))}
      </td>
      {/* <td style={{ textAlign: "center" }}>{user.transactionCount ?? "-"}</td> */}
      <td style={{ textAlign: "center" }}>{user.zip ?? "-"}</td>
      <td>{user.role === UserRoles.USER && "#" + user.level}</td>
      <td>{format(new Date(user.inscriptionDate), "dd/MM/yyyy")}</td>
      <td>
        <Badge color={user.status === UserStatus.ON ? "blue" : "red"}>
          {user.status === UserStatus.ON ? "actif" : "désactivé"}
        </Badge>
      </td>
      {
        <Group style={{ position: "absolute", right: 10, top: 15, gap: 0 }}>
          <ActionIcon
            variant="transparent"
            onClick={() => {
              setSelectedUser(user);
              setShowSelectedUser(true);
            }}
          >
            <BsPencil size={12} color="black" />
          </ActionIcon>

          <Menu shadow="md" offset={0}>
            <Menu.Target>
              <ActionIcon variant="transparent">
                <BsThreeDots size={12} color="black" />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<FiSend size={16} />}
                onClick={() => navigate("/inbox", { state: { ...user } })}
              >
                Envoyer un message
              </Menu.Item>
              <Menu.Item
                icon={<FiCheck size={16} />}
                onClick={() =>
                  navigate(
                    `/transactions?${
                      user.role === UserRoles.USER ? "to" : "from"
                    }=${user._id}&firstName=${user.firstName}&lastName=${
                      user.lastName
                    }`
                  )
                }
              >
                Afficher les transactions
              </Menu.Item>
              <Menu.Item
                disabled={user.role !== UserRoles.USER}
                icon={
                  <FiInfo size={16} style={{ transform: "rotate(180deg)" }} />
                }
                onClick={() => {
                  const c = confirm(
                    "Êtes-vous sûr de vouloir envoyer un avertissement à cet utilisateur ?"
                  );
                  if (!c) return;
                  sendWarningToUser(
                    {
                      error: console.error,
                      success: () => {
                        toast.success("Avertissement envoyé");
                      },
                    },
                    { userId: user._id }
                  );
                }}
              >
                Envoyer un avertissement
              </Menu.Item>
              <Menu.Item
                icon={
                  user.status === UserStatus.ON ? (
                    <FiX size={16} color={"red"} />
                  ) : (
                    <FiUser size={16} color={"blue"} />
                  )
                }
                onClick={() =>
                  updateUser(
                    {
                      error: console.error,
                      success: (res) => {
                        user.status =
                          user.status === UserStatus.ON
                            ? UserStatus.BAN
                            : UserStatus.ON;
                        setUsers([...users]);
                      },
                    },
                    user._id,
                    {
                      ...user,
                      status:
                        user.status === UserStatus.ON
                          ? UserStatus.BAN
                          : UserStatus.ON,
                    }
                  )
                }
              >
                <Text
                  style={{
                    color: user.status === UserStatus.ON ? "red" : "blue",
                  }}
                >
                  {user.status === UserStatus.ON
                    ? "Bannir l'utilisateur"
                    : "Activer l'utilisateur"}
                </Text>
              </Menu.Item>
              <Menu.Item
                hidden={user.role !== UserRoles.USER}
                icon={<FiX size={16} color={"red"} />}
                onClick={() => {
                  const c = confirm(
                    "Êtes-vous sûr de vouloir supprimer l'utilisateur ? Cette action est irréversible et entrainera une modification de la pyramide concernée"
                  );
                  if (!c) return;
                  deleteUser(
                    {
                      error: console.error,
                      success: () => {
                        fetchUsers();
                      },
                    },
                    user._id
                  );
                }}
              >
                <Text style={{ color: "red" }}>
                  {"Supprimer l'utilisateur"}
                </Text>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      }
    </tr>
  ));

  return (
    <div className={classes.rootContainer}>
      {/* title */}
      <Title order={2}>Utilisateurs</Title>

      {/* button to export csv */}
      <Button
        variant="outline"
        size="xs"
        style={{
          alignSelf: "flex-start",
        }}
        disabled={exportUsersDataIsLoading}
        onClick={async () => {
          setExportUsersDataIsLoading(true);
          await exportUsersData({
            error: (error) => {
              console.error(error);
              toast.error("Une erreur est survenue");
              setExportUsersDataIsLoading(false);
            },
            success: () => {
              toast.success("Données exportées avec succès");
              setExportUsersDataIsLoading(false);
            },
          });
        }}
      >
        Exporter les données
      </Button>

      {/* filters */}
      <Group>
        <Group>
          <Select
            placeholder="Type d'utlisateur"
            data={[
              { value: "", label: "Type d'utlisateur" },
              {
                value: UserRoles.ADMIN,
                label: UserRolesLabel[UserRoles.ADMIN],
              },
              { value: UserRoles.USER, label: UserRolesLabel[UserRoles.USER] },
              {
                value: UserRoles.STAFF,
                label: UserRolesLabel[UserRoles.STAFF],
              },
              {
                value: UserRoles.MERCHANT,
                label: UserRolesLabel[UserRoles.MERCHANT],
              },
            ]}
            onChange={(value) => {
              setUserRole(value as string);
              setUserStaffRolesFilter(undefined);
            }}
          />
          {userRole === UserRoles.STAFF && (
            <Select
              placeholder="Rôle du staff"
              data={[
                { value: "", label: "Rôle du staff" },
                {
                  value: StaffRoles.STAFF,
                  label: StaffRolesLabel[StaffRoles.STAFF],
                },
                {
                  value: StaffRoles.BOUNCER,
                  label: StaffRolesLabel[StaffRoles.BOUNCER],
                },
                {
                  value: StaffRoles.CASHIER,
                  label: StaffRolesLabel[StaffRoles.CASHIER],
                },
              ]}
              onChange={(value) =>
                setUserStaffRolesFilter([value as StaffRoles])
              }
            />
          )}

          <Select
            placeholder="Status"
            data={[
              { value: "", label: "Status" },
              { value: UserStatus.ON, label: "Actif" },
              { value: UserStatus.OFF, label: "Désactivé" },
              { value: UserStatus.BAN, label: "Banni" },
            ]}
            onChange={(value) => setUserStatus(value as string)}
          />
        </Group>
        {/* <Text
        style={{ color: "#3C8CE4" }}
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}
        onClick={() => { }}
      >
        Exporter
      </Text> */}
      </Group>

      <Checkbox
        label="Anniversaires cette semaine"
        checked={isBirthdayWeekFilter}
        onChange={(event) =>
          setIsBirthdayWeekFilter(event.currentTarget.checked)
        }
      />

      {/* body */}
      <Paper
        p="xl"
        shadow="xs"
        style={{
          borderRadius: 10,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#EDF0F2",
          backgroundColor: "white",
        }}
      >
        {/* search input */}
        <TextInput
          placeholder="Recherche par identifiant, nom ou prénom"
          icon={<IoIosSearch size={14} />}
          onChange={(event) => setSearchText(event.currentTarget.value)}
        />

        {/* table */}
        {loading ? (
          <Center m="xl">
            <Loader />
          </Center>
        ) : (
          <Table mt={"xl"}>
            <thead>
              <tr>
                <th>Utilisateur</th>
                <th>Identifiant</th>
                <th>Der. activité</th>
                <th>XP</th>
                <th>Solde</th>
                {/* <th>Nombre tx</th> */}
                <th>Code postal</th>
                <th>Gén.</th>
                <th>Inscription</th>
                <th style={{ width: "15%" }}>Status</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        )}

        {/* pagination */}
        <Group position="center" mt="xl">
          <Pagination
            size="sm"
            page={page}
            onChange={setPage}
            total={totalPages}
            withControls={false}
          />
        </Group>
      </Paper>

      {/* edit user modal */}
      <Modal
        centered
        opened={showSelectedUser}
        onClose={() => {
          setSelectedUser(null);
          setShowSelectedUser(false);
          // Clean id query params if it exists
          cleanIdQueryParams();
        }}
        title={<Title order={4}>Fiche Utilisateur</Title>}
        withCloseButton={false}
        styles={{
          modal: { minWidth: 600 },
          title: { padding: 10, paddingTop: 0 },
          body: { padding: 10 },
        }}
        overflow="outside"
      >
        <Stack align="stretch" spacing="lg" sx={{ height: "100%" }}>
          {/* close button */}
          <ActionIcon
            variant="transparent"
            style={{ position: "absolute", top: "3%", right: "3%" }}
            onClick={() => {
              setSelectedUser(null);
              setShowSelectedUser(false);
              // Clean id query params if it exists
              cleanIdQueryParams();
            }}
          >
            <FiX size={18} color="gray" />
          </ActionIcon>

          {/* first name && last name && username */}
          <Group position="apart" grow>
            <TextInput
              id="firstName"
              label="Prénom"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  firstName: event.currentTarget.value,
                })
              }
              value={selectedUser?.firstName}
            />

            <TextInput
              id="lastName"
              label="Nom"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  lastName: event.currentTarget.value,
                })
              }
              value={selectedUser?.lastName}
            />

            <TextInput
              id="username"
              label="Identifiant"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  username: event.currentTarget.value.replace("@", ""),
                })
              }
              value={"@" + selectedUser?.username}
            />
          </Group>

          {/* role */}
          <Group position="apart" grow>
            <Text>
              <Text style={{ fontWeight: "500", color: "black", fontSize: 14 }}>
                Rôle
              </Text>
              <Text>{UserRolesLabel[selectedUser?.role as UserRoles]}</Text>
            </Text>
            {selectedUser?.role === UserRoles.STAFF && (
              /* TODO: add multiple select */
              <MultiSelect
                label="Rôles du staff"
                data={[
                  {
                    value: StaffRoles.STAFF,
                    label: StaffRolesLabel[StaffRoles.STAFF],
                  },
                  {
                    value: StaffRoles.BOUNCER,
                    label: StaffRolesLabel[StaffRoles.BOUNCER],
                  },
                  {
                    value: StaffRoles.CASHIER,
                    label: StaffRolesLabel[StaffRoles.CASHIER],
                  },
                ]}
                onChange={(value) =>
                  setSelectedUser({
                    ...selectedUser,
                    staffRoles: value as StaffRoles[],
                  })
                }
                value={selectedUser?.staffRoles}
              />
            )}
          </Group>

          {/* email && phone number */}
          <Group position="apart" grow>
            <TextInput
              id="email"
              label="Email"
              type="email"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  email: event.currentTarget.value,
                })
              }
              value={selectedUser?.email}
            />

            <TextInput
              id="phoneNumber"
              label="Téléphone"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  phoneNumber: event.currentTarget.value,
                })
              }
              value={selectedUser?.phoneNumber}
            />
          </Group>

          {/* dof && country */}
          <Group position="apart" grow>
            <TextInput
              id="dob"
              label="Date de naissance"
              type="date"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  dob: event.currentTarget.value,
                })
              }
              value={
                selectedUser?.dob &&
                format(new Date(selectedUser?.dob), "yyyy-MM-dd")
              }
            />

            <TextInput
              id="country"
              label="Pays"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  country: event.currentTarget.value,
                })
              }
              value={selectedUser?.country}
            />
          </Group>

          {/* city && zip */}
          <Group position="apart" grow>
            <TextInput
              id="city"
              label="Ville"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  city: event.currentTarget.value,
                })
              }
              value={selectedUser?.city}
            />

            <TextInput
              id="zip"
              label="ZIP"
              onChange={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  zip: event.currentTarget.value,
                })
              }
              value={selectedUser?.zip}
            />
          </Group>

          {/* notes */}
          <Textarea
            placeholder="Ecrivez ici..."
            label="Notes"
            maxRows={4}
            onChange={(event) =>
              setSelectedUser({
                ...selectedUser,
                note: event.currentTarget.value,
              })
            }
            value={selectedUser?.note}
          />

          {selectedUser?.role === UserRoles.USER && (
            <>
              <Group position="apart" grow>
                <p style={{ fontSize: 12 }}>Niveau: {selectedUser?.level}</p>
                <p style={{ fontSize: 12 }}>
                  Génération: #{selectedUser?.generation.number}
                </p>
              </Group>
              {/* Parents info */}

              {selectedUserParentsInfo && (
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    👥 Parents
                  </p>
                  {selectedUserParentsInfo}
                </div>
              )}
            </>
          )}

          {/* Warnings section */}
          {selectedUser?.role === UserRoles.USER && (
            <div>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                ⚠️ Avertissements envoyés ({userWarnings?.length})
              </p>
              {userWarnings?.map((warning, index) => (
                <p key={index} style={{ fontSize: 12 }}>
                  {index + 1}
                  {") "}
                  {format(new Date(warning.createdAt), "dd/MM/yyyy HH:mm")}
                </p>
              ))}
            </div>
          )}

          {/* action buttons */}
          <Group position="right">
            <Text
              style={{ color: "black" }}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setSelectedUser(null);
                setShowSelectedUser(false);
                // Clean id query params if it exists
                cleanIdQueryParams();
              }}
            >
              Annuler
            </Text>
            <Button
              onClick={() => {
                updateUser(
                  {
                    error: console.error,
                    success: (res) => {
                      users[
                        users.map((user) => user._id).indexOf(selectedUser._id)
                      ] = selectedUser;
                      setUsers([...users]);
                      setSelectedUser(null);
                      setShowSelectedUser(false);
                      // Clean id query params if it exists
                      cleanIdQueryParams();
                    },
                  },
                  selectedUser._id,
                  selectedUser
                );
              }}
            >
              Sauvegarder
            </Button>
          </Group>
        </Stack>
      </Modal>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  rootContainer: {
    display: "flex",
    marginLeft: "35vh",
    flexDirection: "column",
    height: "100%",
    padding: 40,
    paddingTop: 20,
    gap: 20,
  },
}));
