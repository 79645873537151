import { HttpMutators } from "./http-mutators";
import { useHttp } from "./http";

export const useTransactionService = () => {
  const { get, post } = useHttp("/transactions");

  return {
    getTransactions: (mutators: HttpMutators, params?: any) =>
      get(mutators, { params: { ...params } }),
    getTransactionsByProperty: (mutators: HttpMutators, params?: any) =>
      get(mutators, { url: "/property", params }),
    // Not used anymore because of the new commission system
    getTransactionCommission: (
      mutators: HttpMutators,
      id: string,
      params?: any
    ) => get(mutators, { url: `/${id}/commissions`, params }),
    scanTransaction: (mutators: HttpMutators, txId: string) =>
      post(mutators, { url: `/${txId}/scan` }),
    getTransactionById: (mutators: HttpMutators, id: string) =>
      get(mutators, { url: `/${id}` }),
  };
};
