import { useEffect, useRef, useState } from "react";
import {
  createStyles,
  Group,
  Stack,
  Text,
  Pagination,
  ActionIcon,
  Title,
  Select,
  TextInput,
  Image,
  Paper,
  Table,
  Badge,
  Modal,
  Button,
  Textarea,
  Tabs,
  Grid,
  Center,
  Loader,
  Menu,
  SelectItem,
  Switch,
  Divider,
} from "@mantine/core";
import { IoIosSearch } from "react-icons/io";
import { BsEyeFill, BsPencil, BsThreeDots } from "react-icons/bs";
import { CiDiscount1 } from "react-icons/ci";

import { useTranslation } from "react-i18next";
import { DateRangePicker } from "@mantine/dates";
import {
  Product,
  ProductStatus,
  UserRoles,
  Transaction,
  Partner,
} from "../../types";

import format from "date-fns/format";
import {
  convertStringDecimalToNumber,
  firstLetterUpperCase,
  formatDecimalNumber,
  getUserRolesLabel,
} from "../../utils";
import { FiX } from "react-icons/fi";
import {
  useProductService,
  useTransactionService,
  useProductCategoryService,
} from "../../services";
import { usePartnerService } from "../../services/partner";
import { useDebouncedValue } from "@mantine/hooks";
import { toast } from "react-toastify";

const PAGINATION_LIMIT_TRANSACTIONS = 20;
const PAGINATION_LIMIT_PRODUCTS = 20;

export default function Products() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { getProductsByProperty, addProduct, editProduct, removeProduct } =
    useProductService();
  const { getTransactionsByProperty } = useTransactionService();

  const [saveLoading, setSaveLoading] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [productPage, setProductPage] = useState<number>(1);
  const [productTotalPages, setProductTotalPages] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebouncedValue(searchText, 300); // 300ms delay
  const [productStatus, setProductStatus] = useState<string>("");
  const [showEditProductModal, setShowEditProductModal] = useState<
    Product | any
  >();
  const [selectedProduct, setSelectedProduct] = useState<Product | any>();
  const [rowsTransaction, setRowsTransaction] = useState<Transaction[]>([]);
  const [transactionPage, setTransactionPage] = useState<number>(1);
  const [transactionTotalPages, setTransactionTotalPages] = useState<number>(0);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);

  useEffect(() => {
    getProductsByProperty(
      {
        error: console.error,
        loading: (value) => setLoading(value),
        success: (res) => {
          setProducts(res.products);
          setProductTotalPages(
            Math.ceil(res.count / PAGINATION_LIMIT_PRODUCTS)
          );
        },
      },
      {
        text: debouncedSearchText,
        ...(productStatus && { status: productStatus }),
      },
      { page: productPage - 1 }
    );
  }, [debouncedSearchText, productStatus, productPage]);

  const rows = products.map((product) => {
    return (
      <tr key={product._id} style={{ position: "relative" }}>
        <td>
          <Group>
            {/* add conditionnaly styles */}
            <div
              className={classes.imageRowContainer}
              style={
                product.specialOffer?.isActive
                  ? { border: "4px solid #FFD700" }
                  : {}
              }
            >
              <Image
                radius="xs"
                width={80}
                src={product.imageUrl}
                alt={product.imageUrl}
              />
              {/* Icon sale offer */}
              {product.specialOffer?.isActive && (
                <div className={classes.specialOfferIconContainer}>
                  <CiDiscount1 size={24} color="#FFD700" />
                </div>
              )}
            </div>
            <Stack style={{ gap: 0 }}>
              <Text style={{ color: "black" }}>{product.title}</Text>
              <Text>
                {product.status === ProductStatus.ON ? "Actif" : "Brouillon"}
              </Text>
            </Stack>
          </Group>
        </td>
        <td>{product.sponsor}</td>
        <td>{product.points}</td>
        <td>{product.price}€</td>
        <td>{product?.xp || "-"}</td>
        <td>
          {format(new Date(product.start), "dd/MM/yyyy") +
            " - " +
            format(new Date(product.end), "dd/MM/yyyy")}
        </td>
        <td>
          {product.stock - product.used}/{product.stock}
        </td>
        <td>
          <Badge
            size="sm"
            color={product.status === ProductStatus.ON ? "blue" : "gray"}
          >
            {product.status === ProductStatus.ON ? "ACTIF" : "BROUILLON"}
          </Badge>
        </td>
        {
          <Group style={{ position: "absolute", right: 10, top: 20, gap: 0 }}>
            <ActionIcon
              variant="transparent"
              onClick={() => {
                setSelectedProduct(product);
                setShowEditProductModal(true);
                // Reset transaction page when opening the edit product modal
                setTransactionPage(1);
              }}
            >
              <BsPencil size={12} color="black" />
            </ActionIcon>

            {product?.id !== 0 && (
              <Menu shadow="md" offset={0}>
                <Menu.Target>
                  <ActionIcon variant="transparent">
                    <BsThreeDots size={12} color="black" />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<FiX size={16} color={"red"} />}
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowDeleteConfirmationModal(true);
                    }}
                  >
                    <Text style={{ color: "red" }}>Supprimer le produit</Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
        }
      </tr>
    );
  });

  useEffect(() => {
    if (selectedProduct?._id) {
      getTransactionsByProperty(
        {
          error: console.error,
          success: (res) => {
            setRowsTransaction(res.transactions);
            setTransactionTotalPages(
              Math.ceil(res.count / PAGINATION_LIMIT_TRANSACTIONS)
            );
          },
        },
        {
          simple: true,
          product: selectedProduct._id,
          page: transactionPage - 1,
        }
      );
    }
  }, [selectedProduct, transactionPage]);

  return (
    <div className={classes.rootContainer}>
      {/* title */}
      <Title order={2}>Récompenses</Title>

      {/* filters */}
      <Group position="apart">
        <Group>
          <Select
            placeholder="Status"
            data={[
              { value: ProductStatus.DRAFT, label: "Brouillon" },
              { value: ProductStatus.ON, label: "Actif" },
            ]}
            onChange={(value) => setProductStatus(value as string)}
          />
        </Group>

        <Group>
          {/* <Text
          style={{ color: "#3C8CE4" }}
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => { }}
        >
          Exporter
        </Text> */}
          <Button
            onClick={() => {
              setSelectedProduct({});
              setShowEditProductModal(true);
            }}
          >
            Nouveau
          </Button>
        </Group>
      </Group>

      {/* body */}
      <Paper
        p="xl"
        shadow="xs"
        style={{
          borderRadius: 10,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#EDF0F2",
          backgroundColor: "white",
        }}
      >
        {/* search input */}
        <TextInput
          placeholder="Recherche par titre ou sponsor"
          icon={<IoIosSearch size={14} />}
          onChange={(event) => setSearchText(event.currentTarget.value)}
        />

        {/* table */}
        {loading ? (
          <Center m="xl">
            <Loader />
          </Center>
        ) : (
          <Table mt={"xl"}>
            <thead>
              <tr>
                <th>Titre</th>
                <th>Sponsor</th>
                <th>Points</th>
                <th>Prix</th>
                <th>XP</th>
                <th>Période</th>
                <th>Dispo</th>
                <th style={{ width: "15%" }}>Status</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        )}

        {/* pagination */}
        <Group position="center" mt="xl">
          <Pagination
            size="sm"
            page={productPage}
            onChange={setProductPage}
            total={productTotalPages}
            withControls={false}
          />
        </Group>
      </Paper>

      {/* edit product modal */}
      <Modal
        opened={showEditProductModal}
        onClose={() => setShowEditProductModal(false)}
        title={
          <Title order={4}>
            {selectedProduct?._id ? "Fiche Récompense" : "Nouvelle Récompense"}
          </Title>
        }
        withCloseButton={false}
        styles={{
          modal: { minWidth: 700 },
          title: { padding: 10, paddingTop: 0 },
          body: { padding: 10 },
        }}
        overflow="outside"
      >
        {/* close button */}
        <ActionIcon
          variant="transparent"
          style={{ position: "absolute", top: "3%", right: "3%" }}
          onClick={() => setShowEditProductModal(false)}
        >
          <FiX size={18} color="gray" />
        </ActionIcon>
        {selectedProduct?._id ? (
          <Tabs defaultValue="details">
            <Tabs.List>
              <Tabs.Tab style={{ width: "50%" }} value="details">
                Détails
              </Tabs.Tab>
              <Tabs.Tab style={{ width: "50%" }} value="transactions">
                Transactions
              </Tabs.Tab>
            </Tabs.List>

            {/* product info */}
            <Tabs.Panel value="details" pt="xl">
              <ProductInfo
                selectedProduct={selectedProduct}
                onSelectedProductChange={(product: Product) => {
                  setSelectedProduct({ ...product });
                }}
                onSave={(product: Product) => {
                  setProducts(
                    products.map((p) =>
                      p._id === product._id ? { ...product } : p
                    )
                  );
                }}
              />
            </Tabs.Panel>

            {/* product transactions info */}
            <Tabs.Panel value="transactions" pt="xl">
              {/* table */}
              <Table mt={"xl"}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Points</th>
                    <th>XP</th>
                    <th>Utilisateur</th>
                    <th>{"Donneur d'ordre"}</th>
                    <th>Scan</th>
                  </tr>
                </thead>
                <tbody>
                  {rowsTransaction?.map((tx) => {
                    const _points = convertStringDecimalToNumber(tx._points);
                    const _xp = convertStringDecimalToNumber(tx._xp);
                    const _pointsFormatted = formatDecimalNumber(_points);
                    const _xpFormatted = formatDecimalNumber(_xp);
                    return (
                      <tr key={tx._id} style={{ position: "relative" }}>
                        <td>{format(new Date(tx.date), "dd/MM/yyyy HH:mm")}</td>
                        <td>
                          <Text
                            style={{
                              color:
                                _points === 0
                                  ? "black"
                                  : _points > 0
                                  ? "#5FC86D"
                                  : "red",
                            }}
                          >
                            {_points > 0 ? "+" : ""}
                            {_pointsFormatted}
                          </Text>
                        </td>
                        <td>
                          <Text style={{ color: "black" }}>
                            {_xp > 0 ? "+" : ""}
                            {_xpFormatted}
                          </Text>
                        </td>
                        <td>
                          <Stack style={{ gap: 0 }}>
                            <Text style={{ color: "black" }}>
                              {tx.to?.firstName + " " + tx.to?.lastName}
                            </Text>
                            <Text>
                              {tx.to?.role === UserRoles.USER
                                ? "Client"
                                : getUserRolesLabel(
                                    tx.to?.role as UserRoles,
                                    tx.to?.staffRoles
                                  )}
                            </Text>
                          </Stack>
                        </td>
                        <td>
                          {tx.alreadyScanned ? (
                            <Stack style={{ gap: 0 }}>
                              <Text style={{ color: "black" }}>
                                {tx.from?.firstName + " " + tx.from?.lastName}
                              </Text>
                              <Text>
                                {tx.from?.role === UserRoles.USER
                                  ? "Client"
                                  : getUserRolesLabel(
                                      tx.from?.role as UserRoles,
                                      tx.from?.staffRoles
                                    )}
                              </Text>
                            </Stack>
                          ) : (
                            <Text style={{ color: "black" }}>-</Text>
                          )}
                        </td>
                        <td>
                          <Text>{tx.alreadyScanned ? "✅" : "⏳"}</Text>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tabs.Panel>
            <Group position="center" mt="xl">
              <Pagination
                size="sm"
                page={transactionPage}
                onChange={setTransactionPage}
                total={transactionTotalPages}
              />
            </Group>
          </Tabs>
        ) : (
          // ? Why this is duplicate above ?
          <ProductInfo
            selectedProduct={selectedProduct}
            onSelectedProductChange={(product: Product) => {
              setSelectedProduct({ ...product });
            }}
            /* We need this fct because this component is so badly implemented */
            onSave={(product: Product) => {
              setProducts(
                products.map((p) =>
                  p._id === product._id ? { ...product } : p
                )
              );
            }}
          />
        )}
        {/* action buttons */}
        <Group position="right" mt="xl">
          <Text
            style={{ color: "black" }}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setShowEditProductModal(false)}
          >
            Annuler
          </Text>
          <Button
            loading={saveLoading}
            onClick={() => {
              const formData = new FormData();

              for (const key in selectedProduct) {
                //!We need to skip the loop if the property is undefined because the backend parsing is not well implemented (for example undefined will be string "undefined")
                if (!selectedProduct[key]) continue;

                // Again backend is not well implemented and we need to skip the specialOffer property
                if (key === "specialOffer") continue;
                //? Backend expects a string for category (just the label). But we receive an object from the select component
                if (key === "category") {
                  formData.append(key, selectedProduct[key].label);
                } else if (key === "partner") {
                  formData.append(key, selectedProduct[key]?._id);
                } else {
                  formData.append(key, selectedProduct[key]);
                }

                //? We need to remove the imageUrl from the formData before sending to backend because of badly implemented backend code
                if (key === "imageUrl" || key === "imagePath") {
                  formData.delete(key);
                }
              }

              if (selectedProduct._id) {
                editProduct(
                  {
                    error: console.error,
                    loading: (value) => setSaveLoading(value),
                    success: (res) => {
                      products[
                        products.map((product) => product._id).indexOf(res._id)
                      ] = res;
                      setProducts([...products]);
                      setShowEditProductModal(false);
                    },
                  },
                  selectedProduct._id,
                  formData
                );
              } else {
                addProduct(
                  {
                    error: console.error,
                    loading: (value) => setSaveLoading(value),
                    success: (res) => {
                      setProducts([...products, res]);
                      setShowEditProductModal(false);
                    },
                  },
                  formData
                );
              }
            }}
          >
            Sauvegarder
          </Button>
        </Group>
      </Modal>

      {/* delete confirmation modal */}
      <Modal
        opened={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        title={
          <Title order={4}>
            {"Etes-vous sur de vouloir supprimer ce produit ?"}
          </Title>
        }
        withCloseButton={false}
        styles={{
          modal: { minWidth: 600 },
          title: { padding: 10, paddingTop: 0 },
          body: { padding: 10 },
        }}
        overflow="outside"
      >
        {/* close button */}
        <ActionIcon
          variant="transparent"
          style={{ position: "absolute", top: "3%", right: "3%" }}
          onClick={() => setShowDeleteConfirmationModal(false)}
        >
          <FiX size={18} color="gray" />
        </ActionIcon>

        {/* action buttons */}
        <Group position="right" mt="xl">
          <Text
            style={{ color: "black" }}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setShowDeleteConfirmationModal(false)}
          >
            Annuler
          </Text>
          <Button
            color="red"
            onClick={() =>
              removeProduct(
                {
                  error: console.error,
                  success: (res) => {
                    products.splice(
                      products.map((p) => p._id).indexOf(res._id),
                      1
                    );
                    setProducts([...products]);
                    setShowDeleteConfirmationModal(false);
                  },
                },
                selectedProduct._id
              )
            }
          >
            Supprimer
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

const ProductInfo = (props: {
  selectedProduct: Product;
  onSelectedProductChange: (product: any) => void;
  onSave: (product: Product) => void;
}) => {
  const inputFile: any = useRef();
  const { getPartners } = usePartnerService();
  const { toggleProductSpecialOffer, resetSeenByProductSpecialOffer } =
    useProductService();
  const { getProductCategories } = useProductCategoryService();

  const [productCategories, setProductCategories] = useState<SelectItem[]>([]);
  const [loadingProductCategories, setLoadingProductCategories] =
    useState(true);
  const [partnerMode, setPartnerMode] = useState<boolean>(
    !!props.selectedProduct?.partner?._id
  );
  const [partners, setPartners] = useState<SelectItem[]>([]);
  const [loadingPartners, setLoadingPartners] = useState(true);
  const [isSpecialOffer, setIsSpecialOffer] = useState(
    props.selectedProduct?.specialOffer?.isActive
  );

  const specialOfferSeenBy =
    props.selectedProduct?.specialOffer?.seenBy?.length || 0;

  useEffect(() => {
    getProductCategories({
      error: console.error,
      success: (res) => {
        const pc = res.map((category: { label: string }) => ({
          value: category.label,
          label: category.label,
        }));
        setProductCategories(pc);
        setLoadingProductCategories(false);
      },
    });
    getPartners({
      error: console.error,
      success: (res) => {
        const partners = res.map((partner: Partner) => ({
          value: partner._id,
          label: partner.name,
        }));
        setPartners(partners);
        setLoadingPartners(false);
      },
    });
  }, []);

  return (
    <Stack align="stretch" sx={{ height: "100%" }}>
      {props.selectedProduct?._id && (
        <div>
          <Switch
            label="Offre Spéciale"
            style={{ color: "#FFD700" }}
            color="yellow"
            checked={isSpecialOffer}
            onChange={(event) => {
              const isChecked = event.currentTarget.checked;
              toggleProductSpecialOffer(
                {
                  error: console.error,
                  success: (res) => {
                    setIsSpecialOffer(isChecked);
                    const updatedProduct = {
                      ...props.selectedProduct,
                      specialOffer: {
                        isActive: isChecked,
                        seenBy:
                          props.selectedProduct.specialOffer?.seenBy || [],
                      },
                    };
                    props.onSelectedProductChange(updatedProduct);
                    // We need this logic because old dev does not know how to make a react component.
                    props.onSave(updatedProduct);
                    toast.success(
                      isChecked
                        ? "Offre spéciale activée"
                        : "Offre spéciale désactivée"
                    );
                  },
                },
                { productId: props.selectedProduct._id, isActive: isChecked }
              );
            }}
          />
          <Group>
            {/* Icon eyes */}
            <BsEyeFill size={14} />
            <p style={{ fontSize: 12 }}>
              Promo vue par {specialOfferSeenBy} utilisateur
              {specialOfferSeenBy > 1 ? "s" : ""}
            </p>
          </Group>
          <p style={{ fontSize: 10 }}>
            Cette offre spéciale s&apos;affiche une seule fois par utilisateur.
            Vous pouvez réinitialiser les vues pour présenter l&apos;offre à
            tous les utilisateurs à nouveau.
          </p>
          <Button
            disabled={!isSpecialOffer}
            size="xs"
            onClick={() => {
              resetSeenByProductSpecialOffer(
                {
                  error: console.error,
                  success: (res) => {
                    toast.success("Vues réinitialisées");
                    const updatedProduct = {
                      ...props.selectedProduct,
                      specialOffer: {
                        isActive:
                          props.selectedProduct.specialOffer?.isActive || false,
                        seenBy: [],
                      },
                    };
                    props.onSelectedProductChange(updatedProduct);
                    // We need this logic because old dev does not know how to make a react component.
                    props.onSave(updatedProduct);
                  },
                },
                { productId: props.selectedProduct._id }
              );
            }}
          >
            Réinitialiser les vues
          </Button>
          {/* Separator */}
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        </div>
      )}
      {/* image && title && type */}
      <Grid>
        <Grid.Col span={4} style={{ display: "flex", alignItems: "center" }}>
          <input
            id="file"
            type="file"
            ref={inputFile}
            style={{ display: "none" }}
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            onChange={(event) => {
              // @ts-ignore
              event.stopPropagation();
              event.preventDefault();

              // @ts-ignore
              props.selectedProduct.file = event.target.files[0];

              var reader = new FileReader();
              reader.onload = function (event) {
                // @ts-ignore
                props.selectedProduct.imageUrl = event.target.result;
                props.onSelectedProductChange(props.selectedProduct);
              };

              // @ts-ignore
              reader.readAsDataURL(event.target.files[0]);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              width: "100%",
              height: "100%",
              backgroundColor: props.selectedProduct?._id ? "" : "#C3CAD1",
              cursor: "pointer",
            }}
            onClick={() => inputFile.current?.click()}
            role="presentation"
          >
            {!props.selectedProduct?.imageUrl ? (
              <Text style={{ color: "black", fontSize: 30 }}>+</Text>
            ) : (
              <img
                src={props.selectedProduct?.imageUrl}
                alt={props.selectedProduct?.imageUrl}
                style={{ width: "100%", borderRadius: 10 }}
              />
            )}
          </div>
        </Grid.Col>

        <Grid.Col span={"auto"}>
          <Stack align="stretch" style={{ height: "100%", width: "100%" }}>
            <TextInput
              required
              id="title"
              label="Titre"
              onChange={(event) =>
                props.onSelectedProductChange({
                  ...props.selectedProduct,
                  title: event.currentTarget.value,
                })
              }
              value={props.selectedProduct?.title}
            />
            <Select
              required
              placeholder="Status"
              label="Status"
              data={[
                { value: ProductStatus.ON, label: "Actif" },
                { value: ProductStatus.DRAFT, label: "Brouillon" },
              ]}
              value={props.selectedProduct?.status}
              onChange={(value) => {
                props.onSelectedProductChange({
                  ...props.selectedProduct,
                  status: value,
                });
              }}
            />
            <Select
              required
              searchable
              creatable
              disabled={loadingProductCategories}
              placeholder="Categorie"
              label="Categorie"
              data={productCategories}
              getCreateLabel={(query) => `+ Ajouter ${query}`}
              value={props.selectedProduct?.category?.label}
              onChange={(value) => {
                {
                  props.onSelectedProductChange({
                    ...props.selectedProduct,
                    category: { label: value },
                  });
                }
              }}
              onCreate={(query) => {
                const item = { value: query, label: query } as SelectItem;
                setProductCategories([item, ...productCategories]);
                return item;
              }}
            />
          </Stack>
        </Grid.Col>
      </Grid>

      {/* sponsor && points && xp */}
      <Grid>
        <Grid.Col span={6}>
          {partnerMode ? (
            <Select
              required
              placeholder="Partenaire"
              disabled={loadingPartners}
              label="sponsor"
              data={partners}
              value={props.selectedProduct?.partner?._id}
              onChange={(value) => {
                props.onSelectedProductChange({
                  ...props.selectedProduct,
                  // Needs to be _id because we will extract this property when submiting the form data
                  partner: { _id: value },
                });
              }}
            />
          ) : (
            <TextInput
              required
              id="sponsor"
              label="Sponsor"
              onChange={(event) =>
                props.onSelectedProductChange({
                  ...props.selectedProduct,
                  sponsor: event.currentTarget.value,
                })
              }
              value={props.selectedProduct?.sponsor}
            />
          )}
          <Switch
            mt={8}
            label="Partenaire"
            checked={partnerMode}
            onChange={(event) => {
              props.onSelectedProductChange({
                ...props.selectedProduct,
                sponsor: "",
                partner: undefined,
              });
              setPartnerMode(event.currentTarget.checked);
            }}
          />
        </Grid.Col>

        <Grid.Col span={"auto"}>
          <TextInput
            required
            id="points"
            label="Points"
            onChange={(event) =>
              props.onSelectedProductChange({
                ...props.selectedProduct,
                points: event.currentTarget.value,
              })
            }
            value={props.selectedProduct?.points}
          />
        </Grid.Col>

        <Grid.Col span={"auto"}>
          <TextInput
            required
            id="price"
            label="Prix"
            placeholder="€"
            onChange={(event) =>
              props.onSelectedProductChange({
                ...props.selectedProduct,
                price: event.currentTarget.value,
              })
            }
            value={props.selectedProduct?.price}
          />
        </Grid.Col>

        <Grid.Col span={"auto"}>
          <TextInput
            id="xp"
            label="XP"
            placeholder="XP gagné"
            onChange={(event) =>
              props.onSelectedProductChange({
                ...props.selectedProduct,
                xp: event.currentTarget.value,
              })
            }
            value={props.selectedProduct?.xp}
          />
        </Grid.Col>
      </Grid>

      {/* available && stock */}
      <Group position="apart" grow>
        <DateRangePicker
          required
          dropdownPosition="bottom-start"
          label="Disponibilité"
          placeholder="Pick dates range"
          // @ts-ignore
          value={
            props.selectedProduct?.start && [
              new Date(props.selectedProduct?.start),
              new Date(props.selectedProduct?.end),
            ]
          }
          onChange={(values) =>
            values[0] &&
            props.onSelectedProductChange({
              ...props.selectedProduct,
              start: values[0],
              end: values[1],
            })
          }
        />

        <TextInput
          required
          id="stock"
          label="Quantité Disponible"
          onChange={(event) =>
            props.onSelectedProductChange({
              ...props.selectedProduct,
              stock: event.currentTarget.value,
            })
          }
          value={props.selectedProduct?.stock}
        />
      </Group>

      {/* description */}
      <Textarea
        placeholder="Ecrivez ici..."
        label="Description"
        maxRows={4}
        onChange={(event) =>
          props.onSelectedProductChange({
            ...props.selectedProduct,
            description: event.currentTarget.value,
          })
        }
        value={props.selectedProduct?.description}
      />

      {/* product link app */}
      {props.selectedProduct?._id && (
        <div>
          <p style={{ fontSize: 14 }}>Lien in-app produit</p>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>
            generation://shop-product-preview/{props.selectedProduct?._id}
          </p>
        </div>
      )}
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  rootContainer: {
    display: "flex",
    marginLeft: "35vh",
    flexDirection: "column",
    height: "100%",
    padding: 40,
    paddingTop: 20,
    gap: 20,
  },
  imageRowContainer: {
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
  },
  specialOfferIconContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
}));
